import * as amplitude from '@amplitude/analytics-browser'

const trackAmplitudeEvent = ({
  eventName, // The event name to track
  eventProps = {}, // Additional properties for the event
}) => {
  try {
    // Track the event with the provided name and properties
    amplitude.track(eventName, eventProps)

    // Optionally update user properties if userId and email are provided
    if (eventProps?.email) {
      amplitude.setUserId(eventProps.email)
    }
    if (eventProps?.userId) {
      // Create an Identify object for setting custom user properties
      const identify = new amplitude.Identify()
      identify.set('Commerce7 ID', eventProps.userId) // Custom property
      // Send the identify call to Amplitude
      amplitude.identify(identify)
    }
  } catch (error) {
    console.error('Error tracking Amplitude event:', error)
  }
}

export default trackAmplitudeEvent
