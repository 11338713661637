const { wrapRootElement } = require('./gatsby-shared')
const Sentry = require('@sentry/gatsby')
const amplitude = require('@amplitude/analytics-browser')

exports.wrapRootElement = wrapRootElement

// Initialize Sentry
if (process.env.GATSBY_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    environment:
      process.env.NODE_ENV === 'development' || process.env.GATSBY_SENTRY_ENV === 'development'
        ? 'development'
        : 'production',
    beforeSend(event) {
      // Ignore TypeErrors
      if (event.exception) {
        const errorMessage = event.exception.values?.[0]?.type || ''
        if (errorMessage === 'TypeError') {
          return null // Discard the error
        }
      }
      return event // Send all other errors to Sentry
    },
    debug: false,
    integrations: [Sentry.browserTracingIntegration(), Sentry.httpClientIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 0.1,
    release: '1.0.0',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

// Initialize Amplitude
exports.onClientEntry = () => {
  amplitude.init(process.env.GATSBY_AMPLITUDE_API_KEY, {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
    },
  })
}
