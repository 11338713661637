import axios from 'axios'
import * as Sentry from '@sentry/gatsby'

const useAxiosConfig = (tenantName) => {
  axios.defaults.headers.common['Content-Type'] = 'application/json'

  axios.defaults.headers.common['Tenant'] = tenantName

  const requestInterceptor = axios.interceptors.request.use(
    (config) => {
      const url = new URL(config.url, window.location.origin)
      const bypassSentry = url.searchParams.get('bypassSentry') === 'true'
      //Added a bypassSentry search paramater in hooks to not trigger a sentry error if conditions are met, the hook returning an actual error should be handled in the responseInterceptor

      if (!bypassSentry && !config.headers.Authorization) {
        Sentry.captureMessage('Missing headers in Axios request', {
          level: 'error',
          extra: {
            tenant: config.headers['Tenant'],
            hasToken: !!config.headers.Authorization,
            url: config.url,
            headers: config.headers,
          },
        })
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  const responseInterceptor = axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response) {
        const errorData = {
          error_message: error.message,
          status: error.response.status,
          statusText: error.response.statusText,
          url: error.response.config.url,
          method: error.response.config.method,
          data: (() => {
            try {
              const requestData = JSON.parse(error.response.config.data)
              if (requestData.password) {
                requestData.password = '<redacted>'
              }
              return JSON.stringify(requestData)
            } catch (e) {
              // If parsing fails, return the original data
              return error.response.config.data
            }
          })(),
          commerce7_info: JSON.stringify(error.response.data),
          commerce7_errors: JSON.stringify(error.response.data.errors),
        }

        // validation and unauthorized error in sentry

        Sentry.withScope((scope) => {
          // Handle different error types using switch

          switch (error.response.data.type) {
            case 'unauthorized':
              scope.setExtra('error_details', errorData.data)
              scope.setLevel('warning')
              if (error.request.responseURL.includes('login')) {
                Sentry.captureMessage('Failed Login Attempt')
              } else {
                Sentry.captureMessage(error.response.data.message)
              }
              break

            case 'validationError':
              scope.setExtra('error_details', error.response.data.message)
              scope.setLevel('info')
              Sentry.captureMessage('Form Validation Error')
              break

            default:
              scope.setLevel('error')
              scope.setExtra('error_details', error.response.data.message)
              Sentry.captureMessage(error.response.data.message)

              break
          }
        })
      }
      return Promise.reject(error)
    }
  )

  return () => {
    axios.interceptors.request.eject(requestInterceptor)
    axios.interceptors.response.eject(responseInterceptor)
  }
}

export default useAxiosConfig
