import React from "react"
import { Link } from "gatsby"

import { useCustomer, useLogin, useAllocation } from "gatsby-theme-harlan"

import logoSVGDark from "../images/bond-logo-black.png"
import logoSVGLight from "../images/bond-logo.png"

const Header = (props) => {
    const {
        location: { pathname },
    } = props

    const { customer, isLoggedIn, isCustomerLoading } = useCustomer()
    const { logout } = useLogin()
    const { allocation, isAllocationLoading, allocationStatus } =
        useAllocation()

    const isBrowser = typeof window !== "undefined"
    const isHomePage = pathname === "/"

    const shouldHideLinks =
        isBrowser && (window.location.pathname.includes("login-by-link") || window.location.pathname.includes("founders-list") || window.location.pathname.includes("/event/"))

    // if (!isBrowser) {
    //   return null
    // }

    return (
        <header
            className={`main-header flex flex-col items-center
        ${isHomePage ? "home-header" : ""}`}
        >
            <div>
                <Link to="/">
                    <img
                        className="logo bond-logo"
                        alt="BOND - Logo"
                        src={isHomePage ? logoSVGLight : logoSVGDark}
                        width="180"
                        height="31"
                    />
                </Link>
            </div>
            <nav
                id="main-navigation"
                className={`mt-6 ${isHomePage ? "light-menu" : ""} ${shouldHideLinks ? "invisible !mt-2" : ""}`}
            >
                <ul className="w-full flex flex-row flex-wrap h-{24}">
                    {/* {!isCustomerLoading && (!isLoggedIn || !isAllocationLoading) && ( */}
                    {!isCustomerLoading &&
                        (!isLoggedIn || !isAllocationLoading) && (
                            <>
                                {isLoggedIn &&
                                !isAllocationLoading &&
                                (allocationStatus == "offering" ||
                                    allocationStatus == "thank-you") ? (
                                    <>
                                        {!allocation?.metaData?.['skip-letter'] && allocation?.metaData?.['campaign-type'] !== 'fl-onboarding' && allocation?.metaData?.['campaign-type'] !== 'event-offering' && (
                                          <li className="offering-link">
                                            <Link
                                              to="/offering/letter"
                                              activeClassName="link_isActive"
                                            >
                                              Letter
                                            </Link>
                                          </li>
                                        )}

                                        <li className="offering-link">
                                          <Link
                                            to="/offering/allocation"
                                            activeClassName="link_isActive"
                                          >
                                            {allocation?.metaData?.['campaign-type'] == 'fl-onboarding' || allocation?.metaData?.['campaign-type'] == 'event-offering' ? 'Founder’s List Invitation' : 'Allocation'}
                                          </Link>
                                        </li>

                                        {allocation &&
                                            allocation.metaData &&
                                            allocation.metaData[
                                                "show-vintage-notes"
                                            ] !== false && (
                                                <li className="offering-link">
                                                    <Link
                                                        to="/offering/vintage-notes"
                                                        activeClassName="link_isActive"
                                                    >
                                                        Vintage Notes
                                                    </Link>
                                                </li>
                                            )}
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            <Link
                                                to="/philosophy"
                                                activeClassName="link_isActive"
                                            >
                                                Philosophy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/grand-crus"
                                                activeClassName="link_isActive"
                                            >
                                                Grand Crus
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/matriarch"
                                                activeClassName="link_isActive"
                                            >
                                                Matriarch
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/team"
                                                activeClassName="link_isActive"
                                            >
                                                Team
                                            </Link>
                                        </li>

                                        {isLoggedIn ? (
                                            <>
                                                <li>
                                                    <Link
                                                        to="/profile"
                                                        activeClassName="link_isActive"
                                                    >
                                                        Account
                                                    </Link>
                                                </li>
                                                {/* <li>
                      <a
                        className={'cursor-pointer'}
                        onClick={logout}
                      >
                        Logout
                      </a>
                    </li> */}
                                            </>
                                        ) : (
                                            <>
                                                <li>
                                                    <Link
                                                        to={"/allocation"}
                                                        activeClassName="link_isActive"
                                                    >
                                                        Allocation
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/login"
                                                        activeClassName="link_isActive"
                                                    >
                                                        Login
                                                    </Link>
                                                </li>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                </ul>
            </nav>
        </header>
    )
}

export default Header
